@import '../styles/defaults';

.footer {
    padding: 20px 20px 0 20px;
    background: $footer-background !important;
    text-align: center;
    color: $footer-color !important;
    //height: 88px;
    height: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
    //margin-top: 10px;
    a {
        color: $footer-link-color;
        font-weight: bold;
        &:hover {
            color: $footer-link-color-over;
        }
    }

    @media (max-width: 640px) {
        height: 100% !important;
    }

    p {
        text-align: center;
        font-size: 10px;
        padding: 0;
        margin: 3px;

        div {
            width: 25%;
            display: inline-grid;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }

    .homepage-links {
        background-color: #090747;
        padding: 10px;
        margin-bottom: 15px;

        a {
            font-size: 16px;
            
            @media screen and (max-width: 768px) {
                font-size: 14px;
              }
        }
    }

    .logo-alt {
        margin-bottom: 15px;
        width: 5%;

        @media (max-width: 768px) {
            width: 20% !important;
        }
    }
}
