@import "styles/defaults";

@font-face {
  font-family: "Lucida Grande";
  src: url("/data/images/fonts/LucidaGrandeBold.ttf");
}

@font-face {
  font-family: "Knockout-HTF50Welterweight-Regular";
  src: url("/data/images/fonts/Knockout HTF50-WelterweightRegular.otf");
}

@font-face {
  font-family: "DINOT-Regular";
  src: url("/data/images/fonts/DINOT-Regular.otf");
}

@font-face {
  font-family: "DINOT-Medium";
  src: url("/data/images/fonts/DINOT-Medium.otf");
}

@font-face {
  font-family: "DINOT-Black";
  src: url("/data/images/fonts/DINOT-Black.otf");
}

@font-face {
  font-family: "DINOT-Bold";
  src: url("/data/images/fonts/DINOT-Bold.otf");
}

@font-face {
  font-family: "DINOT-BoldItalic";
  src: url("/data/images/fonts/DINOT-BoldItalic.otf");
}

@font-face {
  font-family: "MyriadPro-Regular";
  src: url("/data/images/fonts/MyriadPro-Regular.otf");
}

@font-face {
  font-family: "MyriadPro-Italic";
  src: url("/data/images/fonts/MyriadPro-Italic.otf");
}

@font-face {
  font-family: "Raleway-ExtraBold";
  src: url("/data/images/fonts/Raleway-ExtraBold.ttf");
}

@font-face {
  font-family: "Raleway-LightItalic";
  src: url("/data/images/fonts/Raleway-LightItalic.ttf");
}

@font-face {
  font-family: "Raleway-BoldItalic";
  src: url("/data/images/fonts/Raleway-BoldItalic.ttf");
}

body {
  font-family: "DINOT-Regular";
}

b {
  font-family: "DINOT-Bold";
}

.burger .burger-lines,
.burger .burger-lines:after,
.burger .burger-lines:before {
  background-color: $primary-text-color;
}

.ant-input {
  border-radius: 5px !important;
}

.loading {
  text-align: center;
  margin-top: 200px;
  margin-bottom: 200px;

  h1 {
    margin-top: 50px;
  }
}

button.ant-btn-primary {
  background-color: $primary-btn-bg;
  &:hover,
  &:focus,
  &:active {
    background-color: $primary-btn-bg-hvr;
  }
}

.page {
  color: $primary-dark-text-color;
  background-color: #ffffff;

  main a,
  footer a,
  .cookie a {
    font-weight: bold;
  }

  .ant-layout-header {
    background-color: $primary-color;
    position: fixed;
    z-index: 1000;
    width: 100%;
    @media only screen and (max-width: 800px) {
      padding: 0 0;
    }
    height: 55px;
    line-height: 55px;
  }

  .header-burger-open {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9998;
  }

  .header-title {
    float: left;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 0;
    padding: 0 10px;

    a {
      img {
        height: 40px;
        margin-bottom: 3px;
        padding: 5px 0px;
      }
    }
  }

  .header-burger {
    position: absolute;
    width: auto;
    height: auto;
    top: 5px;
    right: 45px;
    @media only screen and (min-width: 800px) {
      display: none;
    }
  }

  .menu {
    width: 100vw;

    @media only screen and (max-width: 800px) {
      display: none;
    }
    ul {
      background-color: transparent;
      li {
        font-family: "DINOT-Medium";
        a {
          color: $primary-text-color;
          font-size: 20px;
          &:hover {
            color: $primary-text-color-hvr;
          }
        }
      }
    }
    .player-menu {
      a {
        font-family: DINOT-Black;
        font-size: 18px;
        padding: 5px 15px;
        background-color: $player-btn-off-bg;
        border-radius: 0px;
        height: 60px;
        margin-top: 20px;
        color: $player-btn-text-off !important;
        &:hover {
          color: $player-btn-text-off !important;
        }
      }
      &.ant-menu-item-selected a {
        background-color: $player-btn-on-bg;
        color: $player-btn-text-on !important;
      }
    }
  }

  .profile-mobile {
    display: none;
  }

  .menu-burger-open {
    overflow: scroll;
    display: block;
    position: fixed;
    top: 100px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 100px);
    overflow-y: hidden;
    z-index: 100000;
    padding: 20px 0;
    background: rbga(0, 0, 0, 0.8);

    .profile {
      display: none;
    }

    .profile-mobile {
      display: block;
    }
  }

  .site-layout-content {
    background: #383232;
    min-height: 1000px;
  }
}

.ant-menu {
  line-height: 65px;
  &-item {
    padding: 0 4vw !important;
    @media only screen and (max-width: 1550px) {
      padding: 0 3vw !important;
    }
    @media only screen and (max-width: 1288px) {
      padding: 0 2vw !important;
    }
    @media only screen and (max-width: 1080px) {
      padding: 0 10px !important;
    }
    &.ant-menu-item:hover {
      background-color: unset !important;
    }
    &.ant-menu-item-selected {
      background-color: unset !important;
      a {
        text-decoration: underline $menu-selected;
        color: #E40046 !important;
      }
      &.player-menu {
        a {
          text-decoration: unset !important;
        }
      }
    }
  }
  &-submenu {
    &.profile {
      color: $primary-text-color !important;
      margin-right: 50px;
      span {
        margin-top: 15px;
        font-size: 30px
      }
    }
  }
  &-sub {
    border-radius: 2px !important;
    background-color: #333333 !important;
    a:hover {
      text-decoration: underline $menu-selected;
    }
  }
}

.player {
  hr {
    background-color: $secondary-text-color;
  }
}

.main {
  background-color: $primary-color;
  &--title,
  &--title-h1,
  &--third-section-card-p {
    color: $primary-text-color;
  }
  &--third-section-card {
    background-color: #000000;
  }
  &--second-section-btn {
    padding: 20px;
    background-color: $primary-btn-bg;
    &:hover {
      background-color: $primary-btn-bg-hvr;
    }
  }

  &--btn {
    border-radius: 0;
    background-color: #e11c44 !important;
    border-color: #e11c44 !important;
    background: transparent;
    line-height: 30px !important;
    font-family: "DINOT-Black";
  }
}

.main--schedule,
.schedule-page {
  background-color: $schedule-page-background;
  h1,
  h2 {
    color: $primary-text-color;
  }

  .ant-tabs .ant-tabs-tab-btn,
  .ant-tabs .ant-tabs-tab-btn:hover {
    color: #000000;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-active .ant-tabs-tab-btn:hover,
  .ant-tabs,
  .ant-tabs button:hover {
    color:$primary-text-color !important;
  }

  .ant-tabs-nav-list .ant-tabs-tab {
    border-color:  $schedule-date-bg !important;

    &:hover {
      border-color:  $schedule-date-bg-hover !important;
    }

    &.ant-tabs-tab-active {
      background-color: $schedule-date-bg;

      &:hover {
        background-color: $schedule-date-bg-hover;
      }
    }
  }

  .ant-list-item {
    &-meta-description,
    h4 {
      color: white;
    }
    &-meta-title {
      color: $schedule-speaker !important;
    }
    &-meta-description {
      color: $speaker-desc !important;
    }
  }
}

.ant-layout {
  background-color: #ffffff;
}

.ant-layout-content {
  max-width: 100%;
  width: 100%;
  min-height: calc(100vh - 88px);
  margin: 0 auto 0 auto;
  padding: 45px 0 0 0;
  background-color: #090747 !important;
}

.ant-card {
  border-radius: 3px;
  box-shadow: 0 0 50px -20px #555555;
}

.content-title {
  margin: 80px 40px 20px 40px;
  padding: 0 40px 20px 40px;
  @media only screen and (max-width: 800px) {
    padding: 0 0 0 0;
  }

  h1 {
    margin: 40px 0 0 0;
  }
}

.content-subtitle {
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }

  font-size: 18px;
  font-stretch: expanded;
  font-weight: bold;
}

.content-body {
  padding: 0 40px 40px 40px;
  color: #ffffff !important;
  @media only screen and (max-width: 800px) {
    padding: 40px 0 40px 0;
  }

  margin-left: 40px;
  margin-right: 40px;

  p,
  ul,
  li,
  div {
    font-size: 18px;
    font-stretch: expanded;
  }

  hr {
    opacity: 0.8;
    border: 0;
    height: 1px;
    background-color: #000000;
  }
}

.highlight-color {
  color: $color-highlight !important;
}
.ant-col {
  width: 100%;
}

button.ant-btn-primary {
  line-height: 1em;
  border-radius: 3px;
  border: 0;
  span {
    font-weight: bold;
  }
}

.center-input .ant-form-item-control {
  margin-left: auto;
  margin-right: auto;
}

.right-input {
  float: right;
}

.login,
.register {
  max-width: 70%;
  .content-title {
    .image {
      background-color: white;
    }
  }
  .ant-input {
    background-color: $login-input-bg !important;
    text-align: center;
    
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }

    &::placeholder {
      color: $login-input-placeholder-color;
    }
  }

  a.right-input {
    color: $secondary-text-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-btn-bg;
    border-color: $primary-btn-bg;
  }

  .submit-button {
    line-height: 1.5715;
    background-color: $login-btn-bg;
    color: $login-btn-color;
    &:hover {
      background-color: $login-btn-bg-hvr;
    }

    font-family: "DINOT-Black";
  }

  .register-button {
    background-color: $register-btn-bg;
    color: $register-btn-color;
    &:hover {
      background-color: $register-btn-bg-hvr;
    }
  }

  hr {
    background-color: $register-btn-bg;
  }
  .image {
    border-color: $register-btn-bg !important;
  }
}

.speakers-container {
    .speaker {
        background-color: $speakers-bg;
    }
    .speaker__photo {
        border-color: $speaker-photo;
        background-color: $speaker-photo;
    }
    .speaker__name {
        text-transform: uppercase;
        color: $speakers-title-color;
    }
    .speaker__description {
        color: $speaker-desc;
    }
}

.poll-vote {
  background: radial-gradient(
      circle at 100% 50%,
      transparent 20%,
      rgba(255, 255, 255, 0.3) 21%,
      rgba(255, 255, 255, 0.3) 34%,
      transparent 35%,
      transparent
    ),
    radial-gradient(
        circle at 0% 50%,
        transparent 20%,
        rgba(255, 255, 255, 0.3) 21%,
        rgba(255, 255, 255, 0.3) 34%,
        transparent 35%,
        transparent
      )
      0 -50px;
  background-color: rgba(0, 0, 0, 0.1);
}

.schedule {
    &__content {
        color: $primary-text-color;
    }
}

.title-red-card-top {
  height: 50px !important;
  line-height: 50px !important;
  text-transform: uppercase;
  
  span {
    font-family: 'Knockout-HTF50Welterweight-Regular', Raleway-BoldItalic !important;
    height: 40px;
    font-size: 40px !important;
    line-height: 50px;
    font-style: normal !important;

    @media (max-width: 600px) {
      font-size: 30px !important;
    }

  }
}

.title-red-card {
  //background-image: url('/data/images/red-card.png');
  //background-repeat: no-repeat;
  height: 40px;
  padding-left: 30px;
  line-height: 40px;
  font-size: 40px !important;
  display: flex !important;

  position: relative;
  background: transparent;
  z-index: 100;
  //text-transform: uppercase;
  width: max-content;
  text-align: left;
  margin-left: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    background: #E40046;
    transform: skew(-30deg);
    margin-right: -30px;
  }

  span {
    color: #ffffff;
    font-family: Raleway-BoldItalic;
    font-weight: 800;
  }
}

.ant-menu-submenu-title {
  padding: 0 30px !important;
}
